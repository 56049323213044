import { React, useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import SearchLease from "./SearchLease";
import MainLog from "./Admin/MainLog";
import UserLog from "./Admin/UserLog";
import MainMGMTLog from "./MGMT/MainMGMTLog";
import UserMGMTLog from "./MGMT/UserMGMTLog";
/*import ManagerComponent from "./ManagerComponent"; */
import jwtDecode from "../modules/jwtDecode";

const Dashboard = () => {
  const navigate = useNavigate(); // Correct usage of useNavigate
  const [firstNavigate, setFirstNavigate] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    jwtDecode(storedToken)
      .then((result) => {
        console.log(result);
        const data = result;

        if (!data.success) {
          console.log("Request not successful");
        } else {
          if (data.role === "normal" && firstNavigate) {
            setFirstNavigate(false);
            navigate("/dashboard/search", { replace: true });
          } else if (data.role === "admin" && firstNavigate) {
            setFirstNavigate(false && firstNavigate);
            navigate("/dashboard/admin", { replace: true });
          } else if (data.role === "mgmt" && firstNavigate) {
            setFirstNavigate(false);
            navigate("/dashboard/mgmt", { replace: true });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [navigate]);

  return (
    <Routes>
      <Route path="/search" element={<SearchLease />} />
      <Route path="/admin" element={<MainLog />} />
      <Route path="/admin/:username" element={<UserLog />} />
      <Route path="/mgmt" element={<MainMGMTLog />} />
      <Route path="/mgmt/:username" element={<UserMGMTLog />} />

      {/*      <Route path="/manager" element={<ManagerComponent />} /> */}
    </Routes>
  );
};

export default Dashboard;
