const asyncDecode = async (token) => {
  const response = await fetch(`/user/token`, {
    headers: {
      Authorization: token,
    },
  });
  const data = await response.json();
  return data;
};

module.exports = asyncDecode;
