// Frontend: src/components/Verify.js
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Verify = () => {
  const { username } = useParams();
  const [totp, setTOTP] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleVerify = async () => {
    try {
      const response = await fetch("/user/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, totp }),
      });

      const data = await response.json();

      if (response.ok) {
        // Successful verification, redirect to Login
        navigate("/?success=true");
      } else {
        setError(data.message);
        // Clear the OTP on an unsuccessful verification attempt
        setTOTP("");
      }
    } catch (error) {
      console.error("Error during verification:", error);
      setError("Internal Server Error");
    }
  };

  const handleKeyDown = (e) => {
    // Trigger verification on pressing the Enter key
    if (e.key === "Enter") {
      handleVerify();
    }
  };

  return (
    <div>
      <div className="login-container">
        <h2>Verify</h2>
        <form>
          <label>
            OTP:
            <input
              type="text"
              value={totp}
              onChange={(e) => setTOTP(e.target.value)}
              onKeyDown={handleKeyDown} // Trigger on Enter key
            />
          </label>
          <br />
          <button type="button" onClick={handleVerify}>
            Verify
          </button>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Verify;
