import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const UserLog = () => {
  const { username } = useParams();
  const [searchData, setSearchData] = useState({
    searches: [],
    currentPage: 1,
    totalPages: 1,
  });
  const [viewData, setViewData] = useState({
    views: [],
    currentPage: 1,
    totalPages: 1,
  });
  console.log(viewData.currentPage);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        const searchResponse = await fetch(
          `/log/userSearch?username=${username}&page=${searchData.currentPage}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const searchRes = await searchResponse.json();

        const viewResponse = await fetch(
          `/log/userView?username=${username}&page=${viewData.currentPage}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const viewRes = await viewResponse.json();

        setSearchData(searchRes);
        setViewData(viewRes);
      } catch (error) {
        console.error("Error fetching data for UserLog:", error);
      }
    };

    fetchData();
  }, [username, searchData.currentPage, viewData.currentPage]);

  const renderTable = (data, values, headers) => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((entry) => (
            <tr key={entry._id}>
              {values.map((header) => (
                <td key={header}>{entry[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handlePageChange = (endpoint, direction) => {
    const currentPage =
      endpoint === "search" ? searchData.currentPage : viewData.currentPage;
    const totalPages =
      endpoint === "search" ? searchData.totalPages : viewData.totalPages;

    // Update current page based on direction
    if (direction === "prev" && currentPage > 1) {
      if (endpoint === "search") {
        setSearchData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage - 1,
        }));
      } else {
        setViewData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage - 1,
        }));
      }
    } else if (direction === "next" && currentPage < totalPages) {
      if (endpoint === "search") {
        setSearchData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage + 1,
        }));
      } else {
        setViewData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage + 1,
        }));
      }
    }
  };

  return (
    <div>
      <h1>User: {username}</h1>
      <h2>Searches</h2>
      <div>
        {renderTable(
          searchData.searches,
          ["username", "postcode", "dateOfSearch"],
          ["Username", "Postcode", "Search Date"]
        )}
      </div>
      <div>
        <button
          onClick={() => handlePageChange("search", "prev")}
          disabled={searchData.currentPage === 1}
        >
          {"<"}
        </button>
        <span>
          {searchData.currentPage}/{searchData.totalPages}
        </span>
        <button
          onClick={() => handlePageChange("search", "next")}
          disabled={searchData.currentPage === searchData.totalPages}
        >
          {">"}
        </button>
      </div>

      <h2>Lease viewings</h2>
      <div>
        {renderTable(
          viewData.views,
          [
            "username",

            "associatedPropertyDescription",
            "dateOfLease",
            "term",
            "extractedTerm",
            "associatedPostcode",
            "dateOfSearch",
          ],
          [
            "Username",

            "Associated Property Description",
            "Date of Lease",
            "Term",
            "Extracted Term",
            "Associated Postcode",
            "View Date",
          ]
        )}
      </div>
      <div>
        <button
          onClick={() => handlePageChange("view", "prev")}
          disabled={viewData.currentPage === 1}
        >
          {"<"}
        </button>
        <span>
          {viewData.currentPage}/{viewData.totalPages}
        </span>
        <button
          onClick={() => handlePageChange("view", "next")}
          disabled={viewData.currentPage === viewData.totalPages}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default UserLog;
