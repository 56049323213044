// components/SearchLease.js

import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import loadingGif from "../static/loading.gif"; // Replace with your logo path
import "bootstrap/dist/css/bootstrap.min.css";

const SearchLease = () => {
  const [postcode, setPostcode] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedLease, setSelectedLease] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // New state for loading
  const dropdownRef = useRef(null);

  const handlePostcodeChange = (e) => {
    // Update the postcode as the user types
    setPostcode(e.target.value);
    setErrorMessage("");
    setOptions([]);
  };

  const handleSearch = async () => {
    // Check if the length of the postcode is at least 5
    if (postcode.length < 5) {
      setErrorMessage("Please enter your full postcode.");
      setOptions([]);
      return;
    }
    setLoading(true); // Set loading to true when starting the search

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`/lease/search?postcode=${postcode}`, {
        headers: {
          Authorization: token,
        },
      });
      const data = await response.json();

      if (data.error || data.length == 0) {
        setErrorMessage("No leases found.");
        setOptions([]);
        return;
      }
      setOptions(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false); // Set loading to false when the search is complete
    }
  };

  function expiryCalc(expiryDate) {
    if (expiryDate == "Null") return "See term";
    const currentDate = DateTime.local();
    const expiryArr = expiryDate.split("/");

    for (let i in expiryArr) {
      if (expiryArr[i].length == 1) {
        expiryArr[i] = "0" + expiryArr[i];
      }
    }
    const [month, day, year] = expiryArr;
    const parsedExpiryDate = DateTime.fromObject({ year, month, day });

    if (!parsedExpiryDate.isValid) {
      // Handle invalid date format
      return "N/A";
    }

    const duration = parsedExpiryDate
      .diff(currentDate, ["years", "days"])
      .toObject();
    if (parsedExpiryDate < currentDate) {
      return "Already expired";
    }
    const formattedRemainingTime =
      (duration.years > 0
        ? `${duration.years} year${duration.years > 1 ? "s" : ""}`
        : "") +
      (duration.days > 0
        ? ` ${duration.days.toFixed(2)} day${duration.days > 1 ? "s" : ""}`
        : "");

    return formattedRemainingTime.trim();
  }

  const handleOptionClick = (selectedOption) => {
    // Handle option click
    setSelectedLease(selectedOption);
    expiryCalc(selectedOption.ExtractedTerm);
    // Reset options when an option is clicked
    setOptions([]);

    const token = localStorage.getItem("token");

    fetch(`/user/request`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ lease: selectedOption }),
    });
  };

  const handleKeyPress = (e) => {
    // Trigger search and display options on "Enter" key press
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleSubmit = (e) => {
    // Prevent form submission, handle search, and display options
    e.preventDefault();
    handleSearch();
  };

  useEffect(() => {
    // Reset selectedLease when postcode changes
    setSelectedLease(null);
  }, [postcode]);

  return (
    <div className="search-container text-center">
      <div className="search-div">
        <h2>Search Leaseholds by Postcode</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter full postcode"
            value={postcode}
            onChange={handlePostcodeChange}
            onKeyDown={handleKeyPress}
            className="form-control mb-2"
          />
          <button className="searchSubmit" type="submit">
            Search
          </button>
          {errorMessage && (
            <p className="error-message" style={{ color: "red" }}>
              {errorMessage}
            </p>
          )}
        </form>
        {loading && (
          <img className="loadingGif" src={loadingGif} alt="Loading" />
        )}{" "}
        {/* Loading gif */}
        {options.length > 0 && (
          <ul className="options-dropdown list-unstyled" ref={dropdownRef}>
            {options.map((option) => (
              <li
                key={option._id}
                onClick={() => handleOptionClick(option)}
                className="bg-light p-2 mb-2"
              >
                <p>{option["dpa_single_address_label"]}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* Always render the table */}
      <div className="selected-lease mt-4">
        <h2 className="text-white mb-3">
          <b>Lease Details</b>
        </h2>
        <table className="table table-bordered">
          <tbody>
            {selectedLease && (
              <>
                <tr>
                  <th>Address</th>
                  <td>{selectedLease["dpa_single_address_label"]}</td>
                </tr>
                <tr>
                  <th>Extracted Term</th>
                  <td>
                    {selectedLease.ExtractedTerm === "Null"
                      ? "See term"
                      : selectedLease.ExtractedTerm}
                  </td>
                </tr>
                <tr>
                  <th>Years and Day till expiry:</th>
                  <td>{expiryCalc(selectedLease.ExtractedTerm)}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SearchLease;
