// Frontend: src/App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Verify from "./components/Auth/Verify";
import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import UserLog from "./components/Admin/UserLog";
import jwtDecode from "./modules/jwtDecode";
import "./App.css";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  let userRole;
  useEffect(() => {
    // Check if a token exists in localStorage on app mount
    const storedToken = localStorage.getItem("token");

    if (storedToken) {
      // Decode the token to extract user information (roles/permissions)
      let data;
      jwtDecode(storedToken)
        .then((result) => {
          console.log(result);
          const data = result;

          if (!data.success) {
            setLoggedIn(false);
            return;
          } else {
            setLoggedIn(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const jwt_decode = async (token) => {
    const response = await fetch(`/user/token`, {
      headers: {
        Authorization: token,
      },
    });
    const data = await response.json();

    if (!data.success) {
      setLoggedIn(false);
      return;
    }
    userRole = data.role;
    console.log(userRole);
    setLoggedIn(true);
  };

  const handleLogin = () => {
    // Implement your authentication logic here
    // For simplicity, let's assume successful login for now
    setLoggedIn(true);
  };

  const handleLogout = () => {
    // Implement your logout logic here
    localStorage.removeItem("token"); // Remove token from localStorage on logout
    localStorage.removeItem("role");
    setLoggedIn(false);
  };

  return (
    <div className="App">
      <Router>
        <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/dashboard" />
              ) : (
                <>
                  <Login onLogin={handleLogin} />
                </>
              )
            }
          />
          <Route
            path="/dashboard/*"
            element={
              isLoggedIn ? (
                <Dashboard userRole={userRole} onLogout={handleLogout} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/register"
            element={
              isLoggedIn ? <Dashboard onLogout={handleLogout} /> : <Register />
            }
          />
          <Route path="/verify/:username" element={<Verify />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
