// Frontend: src/components/Register.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Register = ({ onRegister }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      if (password !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }

      const response = await fetch("/user/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Successful registration
        navigate(`/verify/${username}`);
      } else {
        setError(data.message);
        // Clear the password and confirm password on an unsuccessful registration attempt
        setPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setError("Internal Server Error");
    }
  };

  const handleKeyDown = (e) => {
    // Trigger registration on pressing the Enter key
    if (e.key === "Enter") {
      handleRegister();
    }
  };

  useEffect(() => {
    // Check if passwords match when the confirm password value changes
    setPasswordsMatch(password === confirmPassword && password != "");
  }, [password, confirmPassword]);

  return (
    <div>
      <div className="login-container">
        <h2>Register</h2>
        <form>
          <label>
            Username:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
          <br />
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown} // Trigger on Enter key
            />
          </label>
          <br />
          <label>
            Confirm Password:
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onKeyDown={handleKeyDown} // Trigger on Enter key
              style={{
                borderColor:
                  passwordsMatch === null
                    ? ""
                    : passwordsMatch
                    ? "green"
                    : "red",
              }}
            />
            {passwordsMatch !== null && (
              <span
                style={{
                  marginLeft: "5px",
                  color: passwordsMatch ? "green" : "red",
                }}
              >
                {passwordsMatch ? "✔" : "✘"}
              </span>
            )}
          </label>
          <br />
          <button type="button" onClick={handleRegister}>
            Register
          </button>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Register;
