// Frontend: src/components/Login.js
import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [requiresVerification, setRequiresVerification] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Check if the URL contains a success message for account verification
    const params = new URLSearchParams(location.search);
    if (params.get("success")) {
      setVerificationSuccess(true);
      // Clear the URL parameters
      window.history.replaceState({}, document.title, location.pathname);
    }
  }, [location.search]);

  const handleLogin = async () => {
    try {
      const response = await fetch("/user/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      console.log(data);
      if (response.ok) {
        // Successful login
        localStorage.setItem("token", data.token); // Save token to localStorage
        onLogin();
      } else {
        setError(data.message);

        // Check if 2FA verification is required
        if (data.requiresVerification) {
          setRequiresVerification(true);
        }

        // Clear the password on an incorrect login attempt
        setPassword("");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("Internal Server Error");
    }
  };

  const handleKeyDown = (e) => {
    // Trigger login on pressing the Enter key
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  // Redirect to Verify component if 2FA verification is required
  if (requiresVerification) {
    return <Navigate to={`/verify/${username}`} />;
  }

  return (
    <div>
      <div className="login-container">
        <h2>Login</h2>
        {verificationSuccess && (
          <p style={{ color: "green" }}>
            Account verified! You can now log in.
          </p>
        )}

        <form>
          <label>
            Username:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown} // Trigger on Enter key
            />
          </label>
          <br />
          <label>
            Password:
            <input
              type="password" // Change to password type
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown} // Trigger on Enter key
            />
          </label>
          <br />
          <button type="button" onClick={handleLogin}>
            Login
          </button>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </form>
        <p>
          Don't have an account? <Link to="/register">Register</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
