import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SearchLease from "../SearchLease";

const MainMGMTLog = () => {
  const [searchData, setSearchData] = useState({
    searches: [],
    currentPage: 1,
    totalPages: 1,
  });
  const [viewData, setViewData] = useState({
    views: [],
    currentPage: 1,
    totalPages: 1,
  });
  const [userCounts, setUserCounts] = useState([]);
  const [filterOption, setFilterOption] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchData = async (endpoint, page, setData) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`/log${endpoint}?page=${page}`, {
        headers: {
          Authorization: token,
        },
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(`Error fetching data for ${endpoint}:`, error);
    }
  };

  const fetchUserCounts = async () => {
    const token = localStorage.getItem("token");
    const constrictionQuery =
      filterOption === "custom"
        ? `constrictions=${filterOption}&startDate=${startDate}&endDate=${endDate}`
        : `constrictions=${filterOption}`;

    try {
      const response = await fetch(`/log/userCount?${constrictionQuery}`, {
        headers: {
          Authorization: token,
        },
      });
      const data = await response.json();
      setUserCounts(data.userCounts);
    } catch (error) {
      console.error("Error fetching user counts:", error);
    }
  };

  useEffect(() => {
    fetchData("/searchMGMT", searchData.currentPage, setSearchData);
    fetchData("/viewMGMT", viewData.currentPage, setViewData);
    fetchUserCounts();
  }, [
    searchData.currentPage,
    viewData.currentPage,
    filterOption,
    startDate,
    endDate,
  ]);

  const renderTable = (data, values, headers) => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((entry) => (
            <tr key={entry._id}>
              {values.map((header) => (
                <td key={header}>
                  {/* Make username a link */}
                  {header === "username" ? (
                    <Link to={`${entry[header]}`}>{entry[header]}</Link>
                  ) : (
                    entry[header]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderUserCountsTable = (userCounts) => {
    const totalSearches = userCounts.reduce(
      (acc, user) => acc + user.searchCount,
      0
    );
    const totalViews = userCounts.reduce(
      (acc, user) => acc + user.viewCount,
      0
    );

    return (
      <div>
        <h2>Totals by User</h2>
        <div className="filter-log-container">
          <label htmlFor="filterOption">Filter By: </label>
          <select
            id="filterOption"
            onChange={(e) => setFilterOption(e.target.value)}
            value={filterOption}
          >
            <option value="all">All</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
            <option value="custom">Custom</option>
          </select>
          {filterOption === "custom" && (
            <form>
              <label htmlFor="startDate">Start Date: </label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label htmlFor="endDate">End Date: </label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </form>
          )}
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Username</th>
              <th>Search Count</th>
              <th>View Count</th>
            </tr>
          </thead>
          <tbody>
            {userCounts.map((user) => (
              <tr key={user.username}>
                <td>{user.username}</td>
                <td>{user.searchCount}</td>
                <td>{user.viewCount}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td>{totalSearches}</td>
              <td>{totalViews}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  const handlePageChange = (endpoint, direction) => {
    const currentPage =
      endpoint === "search" ? searchData.currentPage : viewData.currentPage;
    const totalPages =
      endpoint === "search" ? searchData.totalPages : viewData.totalPages;

    // Update current page based on direction
    if (direction === "prev" && currentPage > 1) {
      if (endpoint === "search") {
        setSearchData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage - 1,
        }));
      } else {
        setViewData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage - 1,
        }));
      }
    } else if (direction === "next" && currentPage < totalPages) {
      if (endpoint === "search") {
        setSearchData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage + 1,
        }));
      } else {
        setViewData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage + 1,
        }));
      }
    }
  };

  return (
    <div>
      <h1>Search</h1>
      <SearchLease />
      <h1>Management Portal</h1>
      <h2>Searches</h2>
      <div>
        {renderTable(
          searchData.searches,
          ["username", "dateOfSearch"],
          ["Username", "Search Date"]
        )}
      </div>
      <div>
        <button
          onClick={() => handlePageChange("search", "prev")}
          disabled={searchData.currentPage === 1}
        >
          {"<"}
        </button>
        <span>
          {searchData.currentPage}/{searchData.totalPages}
        </span>
        <button
          onClick={() => handlePageChange("search", "next")}
          disabled={searchData.currentPage === searchData.totalPages}
        >
          {">"}
        </button>
      </div>
      <h2>Lease viewings</h2>
      <div>
        {renderTable(
          viewData.views,
          ["username", "dateOfSearch", "extractedTerm"],
          ["Username", "View Date", "Unexpired Term"]
        )}
      </div>
      <div>
        <button
          onClick={() => handlePageChange("view", "prev")}
          disabled={viewData.currentPage === 1}
        >
          {"<"}
        </button>
        <span>
          {viewData.currentPage}/{viewData.totalPages}
        </span>
        <button
          onClick={() => handlePageChange("view", "next")}
          disabled={viewData.currentPage === viewData.totalPages}
        >
          {">"}
        </button>
      </div>
      <hr style={{ color: "white" }} />
      {renderUserCountsTable(userCounts)}
    </div>
  );
};

export default MainMGMTLog;
