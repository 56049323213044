import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const MainLog = () => {
  const [searchData, setSearchData] = useState({
    searches: [],
    currentPage: 1,
    totalPages: 1,
  });
  const [viewData, setViewData] = useState({
    views: [],
    currentPage: 1,
    totalPages: 1,
  });

  const fetchData = async (endpoint, page, setData) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`/log${endpoint}?page=${page}`, {
        headers: {
          Authorization: token,
        },
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(`Error fetching data for ${endpoint}:`, error);
    }
  };

  useEffect(() => {
    fetchData("/search", searchData.currentPage, setSearchData);
    fetchData("/view", viewData.currentPage, setViewData);
  }, [searchData.currentPage, viewData.currentPage]);

  const renderTable = (data, values, headers) => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((entry) => (
            <tr key={entry._id}>
              {values.map((header) => (
                <td key={header}>
                  {/* Make username a link */}
                  {header === "username" ? (
                    <Link to={`${entry[header]}`}>{entry[header]}</Link>
                  ) : (
                    entry[header]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handlePageChange = (endpoint, direction) => {
    const currentPage =
      endpoint === "search" ? searchData.currentPage : viewData.currentPage;
    const totalPages =
      endpoint === "search" ? searchData.totalPages : viewData.totalPages;

    // Update current page based on direction
    if (direction === "prev" && currentPage > 1) {
      if (endpoint === "search") {
        setSearchData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage - 1,
        }));
      } else {
        setViewData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage - 1,
        }));
      }
    } else if (direction === "next" && currentPage < totalPages) {
      if (endpoint === "search") {
        setSearchData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage + 1,
        }));
      } else {
        setViewData((prevData) => ({
          ...prevData,
          currentPage: prevData.currentPage + 1,
        }));
      }
    }
  };

  return (
    <div>
      <h2>Searches</h2>
      <div>
        {renderTable(
          searchData.searches,
          ["username", "postcode", "dateOfSearch"],
          ["Username", "Postcode", "Search Date"]
        )}
      </div>
      <div>
        <button
          onClick={() => handlePageChange("search", "prev")}
          disabled={searchData.currentPage === 1}
        >
          {"<"}
        </button>
        <span>
          {searchData.currentPage}/{searchData.totalPages}
        </span>
        <button
          onClick={() => handlePageChange("search", "next")}
          disabled={searchData.currentPage === searchData.totalPages}
        >
          {">"}
        </button>
      </div>

      <h2>Lease viewings</h2>
      <div>
        {renderTable(
          viewData.views,
          [
            "username",

            "associatedPropertyDescription",
            "dateOfLease",
            "term",
            "extractedTerm",
            "associatedPostcode",
            "dateOfSearch",
          ],
          [
            "Username",

            "Associated Property Description",
            "Date of Lease",
            "Term",
            "Extracted Term",
            "Associated Postcode",
            "View Date",
          ]
        )}
      </div>
      <div>
        <button
          onClick={() => handlePageChange("view", "prev")}
          disabled={viewData.currentPage === 1}
        >
          {"<"}
        </button>
        <span>
          {viewData.currentPage}/{viewData.totalPages}
        </span>
        <button
          onClick={() => handlePageChange("view", "next")}
          disabled={viewData.currentPage === viewData.totalPages}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default MainLog;
