// components/Header.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../static/logo.png"; // Replace with your logo path

const Header = ({ isLoggedIn, onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate("/"); // Navigate to the home page after logout
  };

  return (
    <header className="app-header">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="heading">Search Lease</div>
      {isLoggedIn && (
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      )}
    </header>
  );
};

export default Header;
